import Heading from '@comp/Headings/Heading';
import React from 'react';
import { NavLink } from 'react-router-dom';

const redirectionTargets = {
  '/alltrip/post/fukuoka-4-days-3-nights-itinerary/': 'https://luxury-select.co.kr/blog/fukuoka-4-days-3-nights-itinerary',
  '/alltrip/post/3-best-yeouido-fireworks-festival-tips-and-hotels/': 'https://luxury-select.co.kr/blog/yeouido-fireworks-festival-tips-and-hotels',
  '/alltrip/post/top-5-popular-resorts-in-nha-trang/': 'https://luxury-select.co.kr/blog/top-5-popular-resorts-in-nha-trang',
  '/alltrip/post/hidden-thai-vacance-spots-5/': 'https://luxury-select.co.kr/blog/hidden-thailand-island-5-for-a-relaxing-vacation',
  '/alltrip/post/top-5-best-hotel-breakfasts-in-seoul/': 'https://luxury-select.co.kr/blog/top-5-best-hotel-breakfasts-in-seoul',
  '/alltrip/post/japan-manners-and-customs-5-travelers-must-know/': 'https://luxury-select.co.kr/blog/5-precautions-and-etiquette-for-traveling-to-japan',
  '/alltrip/post/5-best-namsan-mountain-view-hotels/': 'https://luxury-select.co.kr/blog/best-namsan-mountain-and-han-river-view-hotels',
  '/alltrip/post/top-5-kids-friendly-hotel/': 'https://luxury-select.co.kr/blog/top-5-kid-friendly-hotels-with-swimming-pools',
  '/alltrip/post/vietnam-best-5-holiday-spots-and-resorts/': 'https://luxury-select.co.kr/blog/vietnam-best-5-holiday-spots-and-resorts',
  '/alltrip/post/best-5-danang-private-pool-villa/': 'https://luxury-select.co.kr/blog/danang-private-poolvilla',
};

function PostThumbnail({
  onClick,
  titleHeadingLevel=3,
  SubFuncUi,
  fullWidth,

  to, // built at service

  // remote data
  title,
  image,
  // bookmark_id,
  // postId, // data.id
  // region_id,
  // title,
  // distance,
  // image,
  // publisher,
  // bookmark_id,
}) {
  const mergedClass = `block ${fullWidth ? 'w-full pt-[100%]' : 'w-72 h-60'} rounded bg-center bg-no-repeat bg-cover overflow-hidden relative`;
  const contentClass = `w-full h-full ${fullWidth ? 'p-8' : 'p-5'} left-0 top-0 absolute flex-col justify-start items-start gap-4 flex`;
  const subUiClass = `w-6 h-6 ${fullWidth ? 'right-8 top-8' : 'right-5 top-5'} absolute`;
  
  return redirectionTargets[to] ? <a
    href={`${window.location.protocol}//${window.location.host}${to}`}
    onClick={onClick}
    className={mergedClass}
      style={image ? {
      backgroundImage: `url('${image}')`,
    } : {}}
  >
    <div className="w-full h-full left-0 top-0 absolute bg-gradient-to-b from-allstay-gray-1e opacity-70" />
    <div className={contentClass}>
      <div className="flex-col justify-start items-start gap-4 flex">
        <Heading
          className="text-wrap break-keep min-w-44 w-[80%] text-white text-base font-semibold leading-normal"
          level={titleHeadingLevel}
        >
          {title}
        </Heading>
        {/* 태그칩 영역
        <div className="flex justify-start items-start gap-1 flex-wrap">
          {tags.map((t) => <Chip>{t}</Chip>)}
        </div> */}
      </div>
    </div>
    {SubFuncUi ? <div className={subUiClass}>
      <SubFuncUi />
    </div>: ''}
  </a> : <NavLink
    to={to}
    onClick={onClick}
    className={mergedClass}
      style={image ? {
      backgroundImage: `url('${image}')`,
    } : {}}
  >
  <div className="w-full h-full left-0 top-0 absolute bg-gradient-to-b from-allstay-gray-1e opacity-70" />
  <div className={contentClass}>
    <div className="flex-col justify-start items-start gap-4 flex">
      <Heading
        className="text-wrap break-keep min-w-44 w-[80%] text-white text-base font-semibold leading-normal"
        level={titleHeadingLevel}
      >
        {title}
      </Heading>
      {/* 태그칩 영역
      <div className="flex justify-start items-start gap-1 flex-wrap">
        {tags.map((t) => <Chip>{t}</Chip>)}
      </div> */}
    </div>
  </div>
  {SubFuncUi ? <div className={subUiClass}>
    <SubFuncUi />
  </div>: ''}
  </NavLink>;
}

export default PostThumbnail;
