import React, {useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, useLoaderData, useNavigate } from 'react-router-dom';
import InterSection from '@comp/Layouts/InterSection';
import Section from '@comp/Layouts/Section';
import { HorizontalScrollContainer } from '@comp/HorizontalScroll';
import { ReactComponent as ArrowLeftIcon } from '@asset/icon/arrow_left.svg'
import PostThumbnail from '@comp/PostThumbnail/PostThumbnail';
import { PhillWhenToggled } from '@comp/TogglePhill';
import { TogglePlainText, TogglePlainTextUpLine } from '@comp/TogglePlainText';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { updateBookmarkId } from '@store/tags';
import { updateStoreBookmarkId } from '@store/blogPosts';
import FavoriteButtonLight from '@comp/Interested/FavoriteButtonLight';
import { ShareBtn } from '@comp/Share';
import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { makeReachScrollDepthThresholdsStream } from '@stream/instanceScroll';
import { subscribeIsScrolledToBottom } from '@stream/scroll';
import { ModalSpinner } from '@comp/ModalSpinner';


function Magazine() {
  const data = useLoaderData();
  const {
    tags,
    regions,
    location_type,
    activeTag,
    activeRegion,
    seo_meta,
  } = data;
  const [posts, setPosts] = useState(data.posts);

  const navigate = useNavigate();
  
  const [isMetaRendered, setIsMetaRendered] = useState(false);
  
  useEffect(() => {
    if (!isMetaRendered) return;
    const pathArr = window.location.pathname.split('/');
    
    window.dataLayer.push({
      event: `alltrip_magazine__pv`,
      as_d1: pathArr[2],
      as_d2: pathArr[3],
      page_title: window.document.title,
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
    });
  }, [data, isMetaRendered]);

  useEffect(() => {
    setPosts(data.posts);
  }, [data]);
  
  const [userInfo, setUserInfo] = useState(getUserInfo());
  useLayoutEffect(() => {
    subscribeUserInfo(setUserInfo);
  }, []);

  const updateDate = (index, postId, bookmark_id) => {
    const newData = posts;
    posts[index].bookmark_id = bookmark_id;
    setPosts(newData);
    updateBookmarkId(postId, bookmark_id);
    updateStoreBookmarkId(postId, bookmark_id);
  };

  // ----- 스크롤 로딩 시작 -----
  const [sliceTo, setSliceTo] = useState(10);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    subscribeIsScrolledToBottom(setIsScrolledToBottom);
  }, []);
  useEffect(() => {
    if (!isScrolledToBottom || isFetching || data?.posts?.length <= sliceTo) return;
    setIsScrolledToBottom(false);
    setIsFetching(true);
    setTimeout(() => {
      setSliceTo(v => v + 10);
      setIsScrolledToBottom(true);
      setIsFetching(false);
    }, 100);
  }, [isScrolledToBottom, isFetching, data, sliceTo]);
  // ----- 스크롤 로딩 끝 -----

  useEffect(() => {
    window.scroll(0, 0);
    const {
      subscribe,
      unsubscribe,
    } = makeReachScrollDepthThresholdsStream();
    subscribe((depth) => {
      if (depth === 0) return;
      window.dataLayer.push({
        event: 'alltrip_magazine__scroll_scroll-depth',
        scroll_depth_percentage: parseInt(depth),
        page_title: window.document.title,
        page_path: window.location.pathname,
        page_location: window.location.href,
        page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <>
    <Helmet onChangeClientState={(a, b, c) => handleChangeHelmetState(a, b, c, () => setIsMetaRendered(true))}>
      <title>{seo_meta.title}</title>
      <meta property="og:title" content={seo_meta.title} />
      <meta name="description" content={seo_meta.description} />
      <meta property="og:description" content={seo_meta.description} />
      <meta property="og:url" content={seo_meta.canonical} />
      <link rel="canonical" href={seo_meta.canonical} />
      <meta name="twitter:title" content={seo_meta.title} />
      <meta name="twitter:description" content={seo_meta.description} />
      <meta property="og:image" content={seo_meta.image} />
      <meta name="twitter:image" content={seo_meta.image} />
    </Helmet>
    <main id="allstay-main">
      <div className="allstay-contents allstay-contents-box">
        <div className="flex flex-col">
          <div className="flex w-full px-5 justify-center items-center py-2">
            <div className="flex w-full h-full items-center">
              <div className="flex w-full h-12 justify-center items-center gap-3">
                <button onClick={() => window.history.length <= 2 ? navigate('/') : navigate(-1)} >
                  <ArrowLeftIcon />
                </button>
                <h2 className="w-full font-bold text-lg text-allstay-gray-1e text-center">매거진</h2>
                <ShareBtn
                  className="w-8 h-8 flex justify-center items-center"
                  size={32}
                  iconSize={24}
                  url={seo_meta.canonical}
                  image={seo_meta.image}
                  onClick={() => {
                    // 공유하기 팝업 창을 띄우는 공유하기 버튼 클릭 이벤트 트래킹
                    const pathArr = window.location.pathname.split('/');
                    window.dataLayer.push({
                      event: 'alltrip_magazine__shareButton',
                      as_d1: pathArr[2],
                      as_d2: pathArr[3],
                      // as_context: `shareButton`,
                      tag_keyword: activeTag?.tag,
                      tag_keyword_order: activeTag?.originIndex + 1,
                      tag_region: activeRegion?.city,
                      tag_region_order: activeRegion?.originIndex + 1,
                      page_title: seo_meta.title,
                      page_path: window.location.pathname,
                      page_location: window.location.href,
                      page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                      sharetype: '',
                      sharetype_click: 0,
                      shareButton_click: 1,
                    });
                  }}
                >
                  {seo_meta.title}
                  <span className="sr-only">공유하기</span>
                </ShareBtn>
              </div>
            </div>
          </div>
          <InterSection>
            <Section gapToken={6}>
              <div className="flex justify-stretch w-full w-max-mobile px-5">
                <NavLink to="/alltrip/magazine/domestic" className="grow text-center">
                  <TogglePlainText isToggled={location_type === 'domestic'}>국내</TogglePlainText>
                </NavLink>
                <NavLink to="/alltrip/magazine/overseas" className="grow text-center">
                  <TogglePlainText isToggled={location_type === 'overseas'}>해외</TogglePlainText>
                </NavLink>
              </div>
              <div className="w-full w-max-mobile">
                <HorizontalScrollContainer
                  centeredSlidesBounds={true}
                  initialSlide={
                    tags
                    // ?.slice(0, 8)
                    ?.findIndex(({ tag_id }) => tag_id === activeTag.tag_id)
                  }
                  slidesOffsetAfter={20}
                  slidesOffsetBefore={20}
                  gap={12}
                  key={`magazine-${location_type}-tag-navs`}
                  id={`magazine-${location_type}-tag-navs`}
                  speed={150}
                  onClickSlides={tags?.map(({ tag_code }) => {
                    return (e) => {
                      if (e.width > document.querySelector('.allstay-contents').clientWidth) {
                        e.activeIndex = e.clickedIndex;
                        e.slideTo(e.clickedIndex);
                      }
                      navigate(`/alltrip/magazine/${location_type}/${tag_code}`);
                    }
                  })}
                >
                  {
                    tags
                      // ?.slice(0, 8)
                      ?.map(({
                        tag,
                        tag_id,
                      }, i) => {
                        const elKey = `magazine-${location_type}-tag-nav-wrapper-${tag_id}`;
                        return <swiper-slide
                          class="w-fit"
                          key={elKey}
                        >
                          <button
                            onClick={() => {
                              window.dataLayer.push({
                                event: 'alltrip_magazine__tag_click',
                                tag_keyword: tag,
                                tag_keyword_order: i + 1,
                                page_title: window.document.title,
                                page_path: window.location.pathname,
                                page_location: window.location.href,
                                page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                              });
                            }}
                            className="magazine-nav-tag"
                            key={`magazine-${location_type}-tag-nav-btn-${tag_id}`}
                            data-tag-index={i}
                            data-tag-active={tag_id === activeTag?.tag_id}
                          >
                            <PhillWhenToggled isToggled={tag_id === activeTag?.tag_id}>{tag}</PhillWhenToggled>
                          </button>
                        </swiper-slide>;
                      }
                    )
                  }
                </HorizontalScrollContainer>
              </div>
              <div className="w-full w-max-mobile shadow-up-line-2px-ee">
                <HorizontalScrollContainer
                    centeredSlidesBounds={true}
                    initialSlide={
                      regions
                      // ?.slice(0, 8)
                      ?.findIndex(({ region_id, city_code }) => ((region_id === activeRegion.region_id) && (city_code === activeRegion.city_code)))
                    }
                    slidesOffsetAfter={20}
                    slidesOffsetBefore={20}
                    gap={20}
                    key={`magazine-${location_type}-city-navs-${activeTag?.tag_id}`}
                    speed={150}
                    onClickSlides={regions?.map(({ country_code, city_code, region_id }) => {
                      return (e) => {
                        if (e.width > document.querySelector('.allstay-contents').clientWidth) {
                          e.activeIndex = e.clickedIndex;
                          e.slideTo(e.clickedIndex);
                        }
                        navigate(`/alltrip/magazine/${location_type}/${activeTag?.tag_code}/${country_code}/${city_code}?region_id=${region_id}`);
                      }
                    })}
                  >
                    {regions?.map(({
                      region_id,
                      name,
                      country_code,
                      city_code,
                      city,
                      // distance,
                    }, i) => {
                      const elKey = `magazine-${location_type}-region-nav-wrapper-${activeTag.tag_id}-${region_id}-${city_code}`;
                      // console.log('((region_id === activeRegion.region_id) && (city_code === activeRegion.city_code))', ((region_id === activeRegion.region_id) && (city_code === activeRegion.city_code)));
                      // console.log(region_id, activeRegion.region_id, city_code, activeRegion.city_code);
                      return <swiper-slide
                        class="w-fit mr-2 last:mr-0"
                        key={elKey}
                      >
                        <button
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'alltrip_magazine__region_click',
                              tag_keyword: activeTag.tag,
                              tag_keyword_order: activeTag.originIndex + 1,
                              tag_region: city,
                              tag_region_order: i + 1,
                              page_title: window.document.title,
                              page_path: window.location.pathname,
                              page_location: window.location.href,
                              page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                            });
                          }}
                          className="curation-nav-region"
                          key={`magazine-${location_type}-region-nav-btn-${activeTag.tag_id}-${country_code}-${region_id}`}
                          data-region-index={i}
                          data-region-active={((region_id === activeRegion.region_id) && (city_code === activeRegion.city_code))}
                        >
                          <TogglePlainTextUpLine isToggled={((region_id === activeRegion.region_id) && (city_code === activeRegion.city_code))}>{location_type === 'overseas' ? city : (name || city)}</TogglePlainTextUpLine>
                        </button>
                      </swiper-slide>;
                    })}
                </HorizontalScrollContainer>
              </div>
            </Section>
            <Section>
                <div className="flex flex-col gap-3 px-5" key={`magazine-${location_type}-${activeTag.tag_id}-${activeRegion.region_id}-posts`}>
                  {posts?.slice(0, sliceTo)?.map(({
                    id,
                    bookmark_id,
                    slug,
                    ...rest
                    // title,
                    // image,
                    // distance,
                    // publisher,
                  }, i) => {
                    return <PostThumbnail
                      fullWidth={true}
                      to={`/alltrip/post/${slug}${slug.endsWith('/') ? '' : '/'}`}
                      key={`magazine-${location_type}-${activeTag?.tag_id}-${activeRegion?.region_id}-post-${id}`}
                      i={i}
                      postId={id}
                      {...rest}
                      SubFuncUi={() => <FavoriteButtonLight
                        ref_model="content"
                        ref_id={id}
                        userInfo={userInfo}
                        bookmark_id={bookmark_id}
                        updateDate={bid => updateDate(i, id, (bid || bookmark_id))}
                      />}
                    />;
                  })}
                </div>
              </Section>
          </InterSection>
        </div>
        {isFetching && <ModalSpinner />}
        {posts?.length >= sliceTo && <div className="flex justify-center items-center h-20">
          <p className="text-allstay-gray-bd text-sm">포스트를 모두 불러왔어요! 💯</p>
        </div>}
      </div>
    </main>
  </>;
}

export default Magazine;
