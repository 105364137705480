import { Route } from 'react-router-dom';
import {
  Search,
  RegionSearchResult,
  Stay,
} from '@page/search';
import { validScheduleAndRoomInfo } from '@util/validScheduleAndRoomInfo';
import { getHotelResult, getHotelReviews, getRegionResult } from '@service/search';
import gtmEvents from '@util/gtm/gtmEvents';
import { getSearchParams } from '@stream/searchParams';
import { resetModal, setModalIsOpened } from '@store/modal';

const listLoader = async ({ params: {
  // country_code,
  search_type,
  region_code,
  theme_id,
}, request }) => {
  const limit = 1;
  const { searchParams } = (new URL(request.url));
  
  if ( ! (searchParams.get('check_in') || searchParams.get('check_out') || searchParams.get('room_info')) ) {
    const sp = new URLSearchParams(getSearchParams())
    !searchParams.get('check_in') && searchParams.set('check_in', sp.get('check_in'))
    !searchParams.get('check_out') && searchParams.set('check_out', sp.get('check_out')) 
    !searchParams.get('room_info') && searchParams.set('room_info', sp.get('room_info'))
  }

  const { isOK, data } = await getRegionResult({
    search_type: theme_id ? 'curation' : search_type,
    region_code,
    limit,
    page: 1,
    searchParams,
    theme_id,
  });

  if (!isOK) throw new Error('상품 리스트 첫 페이지 불러오기 실패');
  const {
    seo_meta,
  } = data;

  return seo_meta;
};

const stayLoader = async ({ params: {
  hotelId,
}, request }) => {
  const searchParamStr = (new URL(request.url)).searchParams.toString();
  resetModal();
  setModalIsOpened(true);
  const [res, review] = await Promise.all([
    getHotelResult({
      hotelId,
      searchParamStr,
    }),
    getHotelReviews({
      hotelId,
      page: 1,
      limit: 20,
    }),
  ].map((p) => p.catch(
    (err) => ({})
  )));
  setModalIsOpened(false);

  if (res.isOK && res.status === 200 && res.data) {
    window.dataLayer.push({ ...gtmEvents['search_stay__pv_count-rank'].getTrackingParams(res.data) });
    const loaderData = {
      ...res.data,
      review: review?.data,
    };
    
    return loaderData;
  }
  throw new Error('비정상 호텔 상세 데이터');
}

const SearchRouteGroup = (
  <Route loader={validScheduleAndRoomInfo}>
    <Route path="search" element={<Search />} />
    <Route
      id="route-stay-detail"
      loader={stayLoader}
      path="hotel/:country_code/:city_code/:hotelId"
      key={window.location.pathname + window.location.search}
    >
      <Route path=":tap" element={<Stay />} key={window.location.pathname + window.location.search} />
      <Route
        index
        element={<Stay />}
        key={window.location.pathname + window.location.search}
      />
    </Route>
    <Route
      path="search/:country_code/:search_type/:region_code"
      element={<RegionSearchResult key={window.location.search} />}
      loader={listLoader}
    />
    <Route
      path="search/:country_code/:search_type/:region_code/curation/:theme_id"
      element={<RegionSearchResult key={window.location.search} />}
      loader={listLoader}
    />
  </Route>
);

export default SearchRouteGroup;
