import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Outlet, ScrollRestoration, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { subscribeModal, readModal, initModal } from '@store/modal';
import { Modal } from '@comp/Modal';
import '@style/style.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { pushClassList } from '@store/horizontalScrolling';

import { SearchPanel } from '@comp/SearchPanel';
import { getSearchPanelVisibility, subscribeSearchPanelVisibility } from '@stream/searchPanelVisibility';
import { Helmet } from 'react-helmet';
import Gnb from '@comp/Gnb/Gnb';

function App() {
  const location = useLocation();
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const pathParams = useParams();
  let scrolling = useRef(null);

  const [searchPanelIsOpen, setSearchPanelIsOpen] = useState(getSearchPanelVisibility());
  const [isHide, setIsHide] = useState();
  const [modal, setModal] = useState(readModal());

  useLayoutEffect(() => {
    subscribeSearchPanelVisibility(setSearchPanelIsOpen);
    subscribeModal(setModal);
    initModal();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsHide(true);
      if (!!scrolling.current) {
        clearTimeout(scrolling.current);
      }
      scrolling.current = setTimeout(() => {
        scrolling.current = null;
        setIsHide(false)
      }, 100);
    };

    setIsHide(false);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, location.search]);

  const handleWheelFallback = e => {
    pushClassList(e.currentTarget.classList);
  }

  const siteName = '쉽고 편하게, 나만의 인생숙소 찾기 | 올스테이';
  const siteUrl = `${process.env.REACT_APP__DEEP_HOST}`;
  const siteDescription = `올스테이에서 모든 유형의 숙소를 최저가로 예약하세요. 위치와 기간 뿐만 아니라 가격, 숙소유형 등 다양한 옵션을 선택해보세요. 올스테이에서 설레는 여행을 준비하세요.`;

  return (
    <>
      <Helmet>
        <title>{siteName}</title>
        {process.env.REACT_APP__MIXED && <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />}
        <meta name="description" content={siteDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteName.replace(' | 올스테이', '')} />
        <meta property="og:site_name" content="숙소가격 비교 올스테이" />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:image" content={`${siteUrl}/allstay_logo_og_img_original.png`} />
        <link rel="canonical" href={siteUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:domain" content="올스테이" />
        <meta name="twitter:title" content={siteName.replace(' | 올스테이', '')} />
        <meta name="twitter:description" content={siteDescription} />
        <meta name="twitter:image" content={`${siteUrl}/allstay_logo_og_img_original.png`} />
      </Helmet>
      <div onWheel={handleWheelFallback}>
        <div id="layout">
          <div id="default-layout">
            <div className="allstay">
              <div className="allstay-layer">
                <div className={`ease-linear duration-50 transition-opacity allstay-partial gnb z-700 ${isHide ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
                  <Gnb />
                </div>
              </div>
              <div className={`${searchPanelIsOpen ? 'hidden' : ''}`}>
                <Outlet context={{
                  location,
                  searchParams,
                  navigate,
                  pathParams,
                }} />
                <ScrollRestoration />
              </div>
            </div>
          </div>
          <footer className="w-full bg-white-100">
          </footer>
        </div>
        <div className="z-700">
          <SearchPanel />
        </div>
        {
          modal.isOpened &&
          <Modal isClosable={modal.isClosable}>
            {modal.template && <modal.template {...modal.data} />}
          </Modal>
        }
      </div>
    </>
  );
}

export default App;
